import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {IPoiSet} from "../../offer/detail/actions/fetch_offer_detail_poi";
import {IPoi} from "../types/IPoi";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {TransportPoiType} from "../utils/PoiType";
import {useOsmPoisRoutesGrouped} from "./use_osm_pois_routes_grouped";

interface IHook {
    hasRoutes: boolean;
    transportPois: Record<TransportPoiType, IPoi[]> | null;
    routes: {
        busRoutes: IPublicTransportRoute[] | null;
        railwayRoutes: IPublicTransportRoute[] | null;
        subwayRoutes: IPublicTransportRoute[] | null;
        tramRoutes: IPublicTransportRoute[] | null;
    };
}

interface IHookStats {
    hasTransportPoisStats: boolean;
    busPoi: IPoi | IPoiSet | null;
    railwayPoi: IPoi | IPoiSet | null;
    subwayPoi: IPoi | IPoiSet | null;
    tramPoi: IPoi | IPoiSet | null;
}

export const useTransportPoisStats = (): IHookStats => {
    const transportPois = useSelector((state: IRPStore) => state.offerDetail.poisStats);
    return useMemo(() => {
        if (transportPois && transportPois.poi) {
            const busPoi = (transportPois.poi.bus_poi?.id && transportPois.poi.bus_poi) || null;
            const railwayPoi = (transportPois.poi.railway_poi?.id && transportPois.poi.railway_poi) || null;
            const subwayPoi = (transportPois.poi.subway_poi?.id && transportPois.poi.subway_poi) || null;
            const tramPoi = (transportPois.poi.tram_poi?.id && transportPois.poi.tram_poi) || null;
            const hasTransportPoisStats = Boolean(busPoi || railwayPoi || subwayPoi || tramPoi);

            return {
                hasTransportPoisStats,
                busPoi,
                subwayPoi,
                railwayPoi,
                tramPoi
            };
        }

        return {
            hasTransportPoisStats: false,
            busPoi: null,
            subwayPoi: null,
            railwayPoi: null,
            tramPoi: null
        };
    }, [transportPois]);
};

export const useTransportPois = (
    transportPoisFromApi: Record<TransportPoiType, IPoi[]>,
    coordinates: {longitude: number; latitude: number; radius: number}
): IHook => {
    const transportPois = transportPoisFromApi;

    const osmPoisRoutesGrouped = useOsmPoisRoutesGrouped(coordinates);
    return useMemo(() => {
        if (transportPois && !Array.isArray(transportPois)) {
            const busRoutes = osmPoisRoutesGrouped?.bus || null;
            const railwayRoutes = osmPoisRoutesGrouped?.train || null;
            const subwayRoutes = osmPoisRoutesGrouped?.subway || null;
            const tramRoutes = osmPoisRoutesGrouped?.tram || null;

            const hasRoutes = Boolean(busRoutes?.length || railwayRoutes?.length || subwayRoutes?.length || tramRoutes?.length);

            return {
                hasRoutes,

                routes: {
                    busRoutes,
                    railwayRoutes,
                    subwayRoutes,
                    tramRoutes
                },
                transportPois
            };
        }

        return {
            hasRoutes: false,
            transportPois: null,
            routes: {
                busRoutes: null,
                railwayRoutes: null,
                subwayRoutes: null,
                tramRoutes: null
            }
        };
    }, [transportPois, osmPoisRoutesGrouped]);
};
